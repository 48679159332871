<template>
    <BT-Blade-Item
        @deleted="journeyDeleted"
        @fetched="journeyFetched"
        @saved="journeySaved"
        bladeClass="mx-1"
        :bladesData="bladesData"
        bladeName="journey-optimizer"
        :canMinimize="false"
        :canPin="false"
        :canSave="false"
        :changeToggle="changeToggle"
        hideBackButton
        hideSaveAndClose
        :itemID="id"
        :loadingMsg="loadingMsg"
        navigation="journeys"
        :onCanEdit="() => { return false; }"
        :onGetSave="getForSave"
        :onPullSuccessAsync="pullJourney"
        :defaultBladeWidth="300"
        :refreshToggle="mRefreshToggle"
        :saveToggle="saveToggle"
        :title="journey ? journey.journeyName : '...Loading'"
        :actualUsedHeight="112">
        <template v-slot="{ item, save }">
            <div @dragover="dragover_handler" @drop="(ev) => { drop_handler(ev, item) }">
                <BT-Snack v-model="msg" />

                <v-list-item dense>
                    <v-list-item-content>
                        <v-list-item-subtitle>When</v-list-item-subtitle>
                        <v-list-item-title v-if="item.startedOn != null">{{ item.startedOn | toLongDateAndTime }}</v-list-item-title>
                        <v-list-item-title v-else>{{ item.dueStartOn | toDayDate }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <BT-Btn v-if="item.startedOn != null && item.endedOn == null"
                            @click="unstartJourney(item)"
                            icon="mdi-undo"
                            isButton
                            small
                            title="Stop Journey"
                            class="error--text" />
                    </v-list-item-action>
                </v-list-item>

                <div class="d-flex align-center px-3 py-0 my-0">
                    <v-checkbox
                        label="Is Ready"
                        v-model="item.isReady" />
                    <v-spacer />
                    <BT-Menu
                        hideHeader
                        icon="mdi-dots-vertical"
                        left
                        max-width="300px"
                        min-width="300px">
                        <template>
                            <BT-Field-Select
                                navigation="locations"
                                v-model="item.startLocationID"
                                itemText="locationName"
                                label="Start Location"
                                :isEditing="item.startedOn == null" />

                            <BT-Field-Select
                                navigation="locations"
                                v-model="item.endLocationID"
                                itemText="locationName"
                                label="End Location"
                                :isEditing="item.endedOn == null" />
                            
                            <BT-Field-Select
                                navigation="users"
                                v-model="item.driverUserID"
                                itemText="userName"
                                label="Driver"
                                :isEditing="item.startedOn == null" />
                        </template>
                    </BT-Menu>
                </div>
                <v-bottom-navigation grow class="transparent">
                    <v-btn title="Weight / Max Weight">
                        <span>{{ item.lastWeight | toDisplayNumber }}/{{ item.maxWeight | toDisplayNumber }}</span>

                        <v-icon small>mdi-weight-kilogram</v-icon>
                    </v-btn>
                    <v-btn title="Packages / Max Packages">
                        <span>{{ item.lastPackages | toDisplayNumber }}/{{ item.maxPackages | toDisplayNumber }}</span>

                        <v-icon small>mdi-cube</v-icon>
                    </v-btn>
                    <v-btn title="Destinations / Max Destinations">
                        <span>{{ item.destinationCount | toDisplayNumber }}/{{ item.maxDestinations | toDisplayNumber }}</span>
                        
                        <v-icon small>mdi-truck</v-icon>
                    </v-btn>
                </v-bottom-navigation>
                <v-btn class="primary" block @click.stop="save">Save & Update</v-btn>
                <BT-List-Endless
                    @select="t => { $emit('selectMovement', t) }"
                    :canRefresh="false"
                    :canSearch="false"
                    :items="item.movements"
                    label="Deliveries"
                    :selectedIDToggle="selectedDeliveryID">
                    <template v-slot:toolbar-right="{ items }">
                        <BT-Print-Order-Slips
                            selectMovements
                            :movements="items" />
                        <v-btn
                            @click.stop="sortJourney(item)"
                            :disabled="item == null || !isLengthyArray(item.movements)"
                            icon
                            title="Auto Sort">
                            <v-icon>mdi-sort-numeric-ascending</v-icon>
                        </v-btn>
                        <v-btn
                            @click.stop="openInMaps(item)"
                            class="extension-action"
                            :disabled="item == null || !isLengthyArray(item.movements)"
                            icon
                            title="View In Google Maps">
                            <v-icon>mdi-google-maps</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:items="{ selectItem }">
                        <v-list style="height: calc(100vh - 380px)" class="overflow-y-auto">
                            <v-list-item-group v-model="selectedDelivery">
                                <drop-list
                                    accepts-type="delivery"
                                    @insert="d => { insertMovement(d, item) }"
                                    :items="item.movements"
                                    @reorder="ev => { reorder(ev, item) }"
                                    style="min-height: 100px;">
                                    <template v-slot:item="da">
                                        <drag
                                            :key="da.item.id"
                                            :data="da.item"
                                            @copy="removeMovement(item, da.index)"
                                            type="delivery">
                                            <v-list-item class="mouse-item" dense @click="selectItem(da.item)" active-class="accent--text" :value="da.item">
                                                <v-list-item-action v-if="isHistoryRouted || item.startedOn != null">
                                                    <v-row class="ma-0 pa-0">
                                                        <v-icon v-if="isHistoryRouted && da.item.routeStatus == 'unknown'" class="warning--text" small>mdi-help</v-icon>
                                                        <v-icon v-else-if="isHistoryRouted" class="success--text" small>mdi-check</v-icon>
                                                        <v-icon v-if="item.startedOn != null && da.item.releasedOn == null" small title="Awaiting pickup">mdi-map-marker-up</v-icon>
                                                        <v-icon v-else-if="item.startedOn != null" :class="da.item.deliveredOn != null ? 'success--text' : 'error--text'" small>mdi-truck</v-icon>
                                                    </v-row>
                                                </v-list-item-action>
                                                <v-list-item-content :title="da.item.destinationLocation.suburb">
                                                    <v-list-item-title>{{ da.item.destinationLocation.companyAccount.companyName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ da.item.destinationLocation | toLocationLine }}</v-list-item-subtitle>
                                                    <v-list-item-subtitle v-if="da.item.bundleName != null">{{ da.item.bundleName }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action class="mouse-over-show">
                                                    <v-list-item-action-text>
                                                        <v-row no-gutters>
                                                            <v-btn icon target="_blank" 
                                                                small
                                                                title="Open In New Tab"
                                                                :to="{ name: 'courier-movement', params: { id: da.item.id } }">
                                                                <v-icon small>mdi-open-in-new</v-icon>
                                                            </v-btn>
                                                            <v-btn icon @click="openCourierOrders(da.item.id)" 
                                                                small
                                                                title="View Courier Orders">
                                                                <v-icon small>mdi-view-list</v-icon>
                                                            </v-btn>
                                                            <Juncture-Builder-Btn
                                                                :movement="da.item"
                                                                @saved="onPathChanged" />
                                                        </v-row>
                                                    </v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </drag>
                                    </template>
                                    <template v-slot:inserting-drag-image="{ data }">
                                        <div>{{ data }}</div>
                                    </template>
                                    <template v-slot:reordering-drag-image />
                                    <template v-slot:feedback="{ data }">
                                        <v-skeleton-loader
                                        type="list-item-avatar-three-line"
                                        :key="data.id"/>
                                    </template>
                                </drop-list>
                            </v-list-item-group>
                        </v-list>
                    </template>
                </BT-List-Endless>
                <!-- <v-expansion-panels v-model="panelV">
                    <BT-Blade-Expansion-Items
                        @select="t => { $emit('selectMovement', t) }"
                        :canExportCSV="false"
                        :canRefresh="false"
                        :canSelect="false"
                        :headers="[
                            { text: 'Destination', value: 'destinationLocation', textFilter: 'toLocationLine' }]"
                        hideActions
                        :items="item.movements"
                        :selectedIDToggle="selectedDeliveryID"
                        showList
                        title="Deliveries">
                        <template v-slot:actions="{ allItems }">
                            <BT-Delivery-Slips-Print
                                :consignmentTextFunction="x => x.order != null ? x.order.seller.companyName : (x.delivery != null ? x.delivery.seller.companyName : null)"
                                movementText="destinationLocation.companyAccount.companyName"
                                useCustomerOrders
                                :movements="allItems" />
                            <v-btn
                                @click.stop="sortJourney(item)"
                                :disabled="item == null || !isLengthyArray(item.movements)"
                                icon
                                small
                                title="Auto Sort">
                                <v-icon small>mdi-sort-numeric-ascending</v-icon>
                            </v-btn>
                            <v-btn
                                @click.stop="openInMaps(item)"
                                class="extension-action"
                                :disabled="item == null || !isLengthyArray(item.movements)"
                                icon
                                small
                                title="View In Google Maps">
                                <v-icon small>mdi-google-maps</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:everything="{ selectItem }">
                            <v-list>
                                <v-list-item-group v-model="selectedDelivery">
                                    <drop-list
                                        accepts-type="delivery"
                                        @insert="d => { insertMovement(d, item) }"
                                        :items="item.movements"
                                        @reorder="ev => { reorder(ev, item) }"
                                        style="min-height: 100px;">
                                        <template v-slot:item="da">
                                            <drag
                                                :key="da.item.id"
                                                :data="da.item"
                                                @copy="removeMovement(item, da.index)"
                                                type="delivery">
                                                <v-list-item class="mouse-item" dense @click="selectItem(da.item)" active-class="accent--text" :value="da.item">
                                                    <v-list-item-action v-if="isHistoryRouted || item.startedOn != null">
                                                        <v-row class="ma-0 pa-0">
                                                            <v-icon v-if="isHistoryRouted && da.item.routeStatus == 'unknown'" class="warning--text" small>mdi-help</v-icon>
                                                            <v-icon v-else-if="isHistoryRouted" class="success--text" small>mdi-check</v-icon>
                                                            <v-icon v-if="item.startedOn != null && da.item.releasedOn == null" small title="Awaiting pickup">mdi-map-marker-up</v-icon>
                                                            <v-icon v-else-if="item.startedOn != null" :class="da.item.deliveredOn != null ? 'success--text' : 'error--text'" small>mdi-truck</v-icon>
                                                        </v-row>
                                                    </v-list-item-action>
                                                    <v-list-item-content :title="da.item.destinationLocation.suburb">
                                                        <v-list-item-title>{{ da.item.destinationLocation.companyAccount.companyName }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ da.item.destinationLocation | toLocationLine }}</v-list-item-subtitle>
                                                        <v-list-item-subtitle v-if="da.item.bundleName != null">{{ da.item.bundleName }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action class="mouse-over-show">
                                                        <v-list-item-action-text>
                                                            <v-row no-gutters>
                                                                <v-btn icon target="_blank" 
                                                                    small
                                                                    title="Open In New Tab"
                                                                    :to="{ name: 'courier-movement', params: { id: da.item.id } }">
                                                                    <v-icon small>mdi-open-in-new</v-icon>
                                                                </v-btn>
                                                                <v-btn icon @click="openCourierOrders(da.item.id)" 
                                                                    small
                                                                    title="View Courier Orders">
                                                                    <v-icon small>mdi-view-list</v-icon>
                                                                </v-btn>
                                                                <Juncture-Builder-Btn
                                                                    :movement="da.item"
                                                                    @saved="onPathChanged" />
                                                            </v-row>
                                                        </v-list-item-action-text>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </drag>
                                        </template>
                                        <template v-slot:inserting-drag-image="{ data }">
                                            <div>{{ data }}</div>
                                        </template>
                                        <template v-slot:reordering-drag-image />
                                        <template v-slot:feedback="{ data }">
                                            <v-skeleton-loader
                                            type="list-item-avatar-three-line"
                                            :key="data.id"/>
                                        </template>
                                    </drop-list>
                                </v-list-item-group>
                            </v-list>
                        </template>
                    </BT-Blade-Expansion-Items>
                </v-expansion-panels> -->
            </div>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { getGoogleMapsURL, compareString, extensionExists, getGoogleMapsLocationLine } from '~helpers';
import { Drag, DropList } from "vue-easy-dnd";
import { firstBy } from 'thenby';

export default {
    name: 'Journey-Optimizer-Blade',
    components: {
        Drag,
        //Drop,
        DropList,
        BTPrintOrderSlips: () => import('~components/BT-Print-Order-Slips.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        JunctureBuilderBtn: () => import('~home/courier-orders/Juncture-Builder-Btn.vue'),
    },
    data: function() {
        return {
            changeToggle: false,
            extensionListener: null,
            isHistoryRouted: false,
            journey: null,
            loadingMsg: null,
            msg: null,
            panelV: 0,
            mRefreshToggle: false,
            saveToggle: false,
            selectedDelivery: null,
            selectedDeliveryID: null,
            showMore: false
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        refreshToggle: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        refreshToggle() {
            this.mRefreshToggle = !this.mRefreshToggle;
        }
    },
    methods: {
        onPathChanged() {
            this.$emit('pathChanged');
        },
        openCourierOrders(id) {
            if (id && this.bladesData) {
                this.bladesData.openBlade({
                    bladeName: 'movement-courier-orders',
                    data: { id: id }
                });
            }
        },
        drop_handler(ev, journey) {
            ev.preventDefault();
            var d = ev.dataTransfer.getData('text');
            this.reorderFromURL(d, journey);
        },
        dragover_handler(ev) {
            ev.preventDefault();
            ev.dataTransfer.dropEffect = 'all';
        },
        async pullForOrderSlips(r) {
            var destList = r.items;
            var rList = [];
            var movIDs = destList.map(x => x.id);
            var mRes = await this.$BlitzIt.api.get('deliveries', '/get/GetConsignmentIDs?id=' + movIDs.toString(), null, null);
            var allMovementConsignmentIDs = mRes.data.data;
            console.log(allMovementConsignmentIDs);
            var allConsignmentIDs = allMovementConsignmentIDs.flatMap(x => x.value);

            var excessIDs = allConsignmentIDs;
            var iter = 0;

            try {
                if (this.isLengthyArray(excessIDs)) {
                //pull in sets of 20
                do {
                    var conIDs = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                    iter += conIDs.length;
                    this.loadingMsg = `${iter} of ${allConsignmentIDs.length} orders`;
                    var ccResults = this.copyDeep(await this.$BlitzIt.store.getAll('customer-orders', { ids: conIDs.toString(), includeDetails: true }));
                    console.log(ccResults);
                    ccResults.forEach(order => {
                        var mRes = allMovementConsignmentIDs.find(x => x.value.some(v => v == order.id));
                        if (mRes != null) {
                            var dest = destList.find(d => d.id == mRes.key);
                            if (dest != null) {
                                rList.push({
                                    dest: dest,
                                    order: order
                                })
                            }
                        }
                    })

                    excessIDs = excessIDs.filter(x => !conIDs.some(y => y == x));
                } while (this.isLengthyArray(excessIDs))
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
         
            return rList;
        },
        reorderFromURL(url, journey) {
            if (url != null) {
                url = url.split('maps/dir/');
                
                if (url.length > 1) {
                    url = url[1];

                    url = url.replaceAll('+', ' ');

                    var addresses = url.split('/');
                    var missedCnt = 0;

                    if (journey != null && journey.movements != null) {
                        journey.movements.forEach(m => {
                            var depAdd = getGoogleMapsLocationLine(m.departureLocation);
                            var depInd = addresses.findIndex(x => compareString(x) == depAdd);
                            if (depInd > -1) {
                                m.departureSortNumber = depInd;
                            }
                            else {
                                missedCnt += 1;
                                console.log(depAdd);
                            }

                            var arrAdd = getGoogleMapsLocationLine(m.destinationLocation);
                            var arrInd = addresses.findIndex(x => compareString(x) == arrAdd);
                            if (arrInd > -1) {
                                m.arrivalSortNumber = arrInd;
                            }
                            else {
                                missedCnt += 1;
                                console.log(arrAdd);
                            }
                        });

                        journey.movements.sort(firstBy(x => x.arrivalSortNumber));

                        if (missedCnt > 0) {
                            var msg = `WARNING! ${missedCnt} addresses not matched.`;
                            this.msg = msg;
                            return msg;
                        }
                    }
                }
            }
            return null;
        },
        async insertMovement(d, journey) {
            if (!journey.movements.some(y => y.id == d.data.id)) {
                try {
                    this.loadingMsg = 'Inserting Delivery';
                    
                    d.data.journeyID = journey.id;

                    var res = await this.$BlitzIt.store.patch('courier-movements', d.data);
                
                    d.data.rowVersion = res.rowVersion;
                    journey.movements.splice(d.index, 0, d.data);
                    
                    var ind = 1;
                    journey.movements.forEach(x => {
                        x.arrivalSortNumber = ind;
                        ind += 1;
                    });
                }
                finally {
                    this.loadingMsg = null;
                }

                //update capacities
                journey.destinationCount = journey.movements.length;
            }
        },
        getForSave(bItem, item) {
            var ind = 1;
            item.movements.forEach(x => {
                x.arrivalSortNumber = ind;
                ind += 1;
            });

            item.updateCapacities = true;
            
            console.log(item);

            return item;
        },
        journeyDeleted() {
            this.$emit('journeyDeleted');
        },
        journeyFetched(journey, bladeData) {
            if (bladeData != null) {
                //save
                if (bladeData.data.movementID != null && journey != null && this.isLengthyArray(journey.movements)) {
                    this.selectedDelivery = journey.movements.find(x => x.id == bladeData.data.movementID);
                    //this.selectedDeliveryID = this.selectedDelivery != null ? this.selectedDelivery.id : null;
                }

                if (bladeData.data.mapsURL != null) {
                    var resMsg = this.reorderFromURL(bladeData.data.mapsURL, journey);
                    this.saveToggle = !this.saveToggle;
                    this.emitExtensionEvent('journeySaved', { journey: journey, message: resMsg });
                }
            }
        },
        journeySaved(originalJourney, journey) {
            originalJourney.lastPackages = journey.lastPackages;
            originalJourney.lastWeight = journey.lastWeight;
            this.changeToggle = !this.changeToggle;
            this.$emit('saved', journey);
        },
        openInMaps(journey) {
            var url = getGoogleMapsURL(journey);

            if (url == null) {
                this.msg = 'No url to open';
            }
            else {
                if (!extensionExists()) {
                    window.open(url, '_blank');
                }
                else {
                    //send journey to extension
                    this.emitExtensionEvent('openJourneyInMaps', { journey: journey, url: url, date: this.$BlitzIt.auth.formTZ(journey.dueStartOn, 'ccc dd LLL yyyy') });
                }
            }
        },
        async pullJourney(journey) {
            journey.movements.sort(firstBy(x => x.arrivalSortNumber));
            journey.movements = journey.movements.map(x => Object.assign({}, { ...x, routeStatus: null }));
            this.journey = journey;
            this.isHistoryRouted = false;
            this.showMore = false;
            this.$emit('pulled', this.journey);

            return journey;
        },
        async removeMovement(journey, index) {
            var mov = journey.movements[index];
            if (mov.deliveredOn != null) {
                return;
            }
            
            journey.movements.splice(index, 1);
            journey.destinationCount = journey.movements.length;
        },
        reorder(event, journey) {
            event.apply(journey.movements);
            
            var ind = 1;
            journey.movements.forEach(x => {
                x.arrivalSortNumber = ind;
                ind += 1;
            });

            if (extensionExists()) {
                //update journey in extension
                var url = getGoogleMapsURL(journey);
                this.emitExtensionEvent('updateJourneyInMaps', { journey: journey, url: url, date: this.$BlitzIt.auth.formTZ(journey.dueStartOn, 'ccc dd LLL yyyy') });
            }
        },
        async sortJourney(journey) {
            try {
                this.loadingMsg = 'Sorting By History';
                this.isHistoryRouted = true;

                var postData = {
                    journeyID: journey.journeyTemplateID,
                    locationIDs: journey.movements.map(x => x.departureLocationID).concat(journey.movements.map(y => y.destinationLocationID)),
                    startLocationID: journey.startLocationID
                };

                var res = await this.$BlitzIt.api.post('journey-archives', postData, null, '/getRouteSuggestion');
                
                console.log('result');
                console.log(res);
                
                var suggestions = res.data.data;
                var misses = [];

                journey.movements.forEach(m => {
                    m.departureSortNumber = 0;
                    m.arrivalSortNumber = 0;
                    m.routeStatus = 'unknown';

                    if (m.departureLocationID != journey.startLocationID) {
                        var depSug = suggestions.find(x => x.locationID == m.departureLocationID);
                    
                        if (depSug != null) {
                            m.departureSortNumber = depSug.sortNumber;
                        }
                    }

                    var arrSug = suggestions.find(x => x.locationID == m.destinationLocationID && x.sortNumber >= m.departureSortNumber);

                    if (arrSug != null) {
                        m.routeStatus = 'found';
                        m.arrivalSortNumber = arrSug.sortNumber;
                    }
                    else {
                        misses.push(m);
                        m.arrivalSortNumber = -1; //m.departureSortNumber + 1;
                    }
                });

                journey.movements.sort(firstBy(x => x.arrivalSortNumber));

                misses.forEach(m => {
                    //check for similar suburbs, etc.
                    var similarBySuburb = journey.movements.filter(f => this.toCompareString(f.destinationLocation.suburb) == this.toCompareString(m.destinationLocation.suburb) && f.arrivalSortNumber > m.departureSortNumber);

                    //check for similar postcodes, etc.
                    if (!this.isLengthyArray(similarBySuburb)) {
                        similarBySuburb = journey.movements.filter(f => this.toCompareString(f.destinationLocation.postcode) == this.toCompareString(m.destinationLocation.postcode) && f.arrivalSortNumber > m.departureSortNumber);
                    }

                    if (this.isLengthyArray(similarBySuburb)) {
                        similarBySuburb.sort(firstBy(x => x.arrivalSortNumber));

                        m.arrivalSortNumber = similarBySuburb[0].arrivalSortNumber;
                    }
                })

                journey.movements.sort(firstBy(x => x.arrivalSortNumber));
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async unstartJourney(journey) {
            if (journey != null && journey.startedOn != null && journey.endedOn == null) {
                //unstart
                try {
                    this.loadingMsg = 'Stopping Journey';

                    var res = await this.$BlitzIt.store.patch('journeys', { id: journey.id, rowVersion: journey.rowVersion, isStarted: false });

                    journey.startedOn = null;
                    journey.rowVersion = res.rowVersion;
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        },
        insertConsignment() {
            console.log('inserting');
        }
    }
}
</script>